import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

const backendURL = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3001/api'

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({ username, password }: {username: string, password: string}, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            let { data } = await axios.post(
                `${backendURL}/auth/signin`,
                {
                    username: username,
                    password: password
                    },
                config
            )
            data.userName = username
            return data
        } catch (error: any) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)