import {Box, Icon, IconButton, SvgIconProps, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {Dispatch, ReactElement, useState} from "react";
import {Menu, MenuItem, ProSidebar} from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {Link} from "react-router-dom";

import "react-pro-sidebar/dist/css/styles.css";
import {useSelector} from "react-redux";
import React from "react";
import {useGetUserByUserIDQuery} from "../../redux/features/uses";

interface IItemInterface
{
    title: string;
    to: string;
    icon: ReactElement<SvgIconProps>;
    selected: string;
    setSelected: Dispatch<string>;
}

const Item = ({ title, to, icon, selected, setSelected }: IItemInterface) => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colours.palette.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to}/>
        </MenuItem>
    );
}
    const Sidebar = () => {

        const theme = useTheme();
        const colours = tokens(theme.palette.mode);
        const [isCollapsed, setIsCollapsed] = useState(false)
        const [selected, setSelected] = useState("Dashboard")
        const auth: any  = useSelector((state: any) => state.auth)
        const {userUUID} = auth;
        const {data:user} = useGetUserByUserIDQuery(userUUID);

        return (
            <Box
                sx={{
                    "& .pro-sidebar-inner": {
                        background: `${colours.palette.primary[400]} !important`,
                    },
                    "& .pro-icon-wrapper": {
                        backgroundColor: "transparent !important",
                    },
                    "& .pro-inner-item": {
                        padding: "5px 35px 5px 20px !important",
                    },
                    "& .pro-inner-item:hover": {
                        color: "#868dfb !important",
                    },
                    "& .pro-menu-item.active": {
                        color: "#6870fa !important",
                    },
                }}
            >
                <ProSidebar collapsed={isCollapsed}>
                    <Menu iconShape="square">
                        {/* LOGO AND MENU ICON */}
                        <MenuItem
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            icon={isCollapsed ? <MenuOutlinedIcon/> : undefined}
                            style={{
                                margin: "10px 0 20px 0",
                                color: colours.palette.grey[100],
                            }}
                        >
                            {!isCollapsed && (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    ml="15px"
                                >
                                    <Typography variant="h3" color={colours.palette.grey[100]}>
                                        ADMIN IS
                                    </Typography>
                                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                        <MenuOutlinedIcon/>
                                    </IconButton>
                                </Box>
                            )}
                        </MenuItem>

                        {!isCollapsed && (
                            <Box mb="25px">
                                <Box textAlign="center">
                                    <Typography
                                        variant="h2"
                                        color={colours.palette.grey[100]}
                                        fontWeight="bold"
                                        sx={{m: "10px 0 0 0"}}
                                    >
                                        {user ? user.firstName : "USER"}
                                    </Typography>
                                </Box>
                            </Box>
                        )}

                        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                            <Item
                                title="Dashboard"
                                to="/"
                                icon={<HomeOutlinedIcon/>}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Typography
                                variant="h6"
                                color={colours.palette.grey[300]}
                                sx={{m: "15px 0 5px 20px"}}
                            >
                                Users
                            </Typography>
                            <Item
                                title="Team"
                                to="/team"
                                icon={<HomeOutlinedIcon/>}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Typography
                                variant="h6"
                                color={colours.palette.grey[300]}
                                sx={{m: "15px 0 5px 20px"}}
                            >
                                Production
                            </Typography>
                            <Item
                            title="Machines"
                            to="/machines"
                            icon={<HomeOutlinedIcon/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                            <Item
                                title="Moulds"
                                to="/moulds"
                                icon={<HomeOutlinedIcon/>}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Log Shift Data"
                                to="/logShiftData"
                                icon={<HomeOutlinedIcon/>}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Machine Performance"
                                to="/machinePerformance"
                                icon={<HomeOutlinedIcon/>}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </Box>
                    </Menu>
                </ProSidebar>
            </Box>
        );
    }
export default Sidebar;