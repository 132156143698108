import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";
import {BaseQueryError, BaseQueryMeta} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const machinePerformanceApi = createApi({
    reducerPath: 'machinePerformanceApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),
    tagTypes: ['machinePerformance'],
    endpoints: build => ({
        PostMachinePerformance: build.mutation({
                query: (body) => ({
                    url: `/machine_performance/`,
                    method: 'POST',
                    body: {
                        shiftMinutes: "720",
                        ...body
                    }
                }),
                transformResponse: (response: {
                    machine_performance: { id: number, createdAt: string, message: string }
                }) => {
                    return response.machine_performance
                }
            }),
        GetMachinePerformance: build.query({
            query:() => ({
                url: `/machine_performance/`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.machine_performance}
        }),
        GetMachinePerformanceDownload: build.query<Blob, null>({
            query:() => ({
                url: `/machine_performance/download/`,
                method: 'GET',
            }),
        }),
    })
})

export const  {
    usePostMachinePerformanceMutation,
    useGetMachinePerformanceQuery,
    useLazyGetMachinePerformanceDownloadQuery,
} = machinePerformanceApi

