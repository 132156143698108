import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";

export const customrsApi = createApi({
    reducerPath: 'customersApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),

    tagTypes: ['customers'],
    endpoints: build => ({
        GetCustomers: build.query({
            query:() => ({
                url: `/customers/`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.customers}
        }),
        GetCustomerByUserID: build.query({
            query:(customerId: string) => ({
                url: `/customers/${customerId}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.customer}
        }),
    })
})

export const  {
    useGetCustomersQuery,
    useGetCustomerByUserIDQuery
} = customrsApi

