import Header from "../../components/Header";
import {Alert, AlertProps, Box, Button, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {
    DataGrid, GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp
} from "@mui/x-data-grid";
import Snackbar from '@mui/material/Snackbar';
import React, {useEffect} from "react";
import {useGetUsersQuery} from '../../redux/features/uses'

import type {FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import {useGetMachinesQuery} from "../../redux/features/machines";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {useGetMouldsQuery} from "../../redux/features/moulds";
import { useGetMachinePerformanceQuery, useLazyGetMachinePerformanceDownloadQuery
} from "../../redux/features/machinePerformance";
import {useSelector} from "react-redux";

interface IRow {
    row: {
        access: string
    }
}

interface IDataRow {
    field: string;
    headerName: string;
    flex?: number;
    cellClassName?: string;
    type?: string;
    headerAlign?: string;
    align?: string;
    renderCell?: ({row: {access}}: IRow) => JSX.Element;
}

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps): any {
    const {setRows, setRowModesModel} = props;

    const handleClick = (id: any) => {
        setRows((oldRows) => [...oldRows, {id, name: '', age: '', isNew: true}]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };
}

function getRowId(row: any) {
    console.log("ROW ROW RWO ", row)
    return row.id;
}

function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
}

const ShiftData = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const {data: machinePerformance,} = useGetMachinePerformanceQuery({});
    const [loading, setLoading]  = React.useState(false);
    const [snackbar, setSnackbar] = React.useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);
    const machineStatus = ['active', 'offline', 'maintenance'];
    const auth: any  = useSelector((state: any) => state.auth)

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    console.log("Machine Performance:", machinePerformance)
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleCloseSnackbar = () => setSnackbar(null);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({children: error.toString(), severity: 'error'});
    }, []);

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        console.log("ID", id)
    };

    const handleCancelClick = (id: GridRowId) => () => {
        console.log("ID", id)
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
        console.log("Users", machinePerformance)
        const editedRow = machinePerformance.find((row: any) => row.ID === id);
        // if (editedRow!.isNew) {
        //     setRows(rows.filter((row: any) => row.ID !== id));
        // }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleDownload = async () => {
        try {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer ' + auth.accessToken);

            const requestOptions = {
                method: "GET",
                headers: myHeaders
            };

            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "/machine_performance/download", requestOptions);

            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = 'machine_performance.csv'; // rename this to your preferred filename
            document.body.appendChild(link);

            link.click();
            link.remove();
            setLoading(false);
        } catch (error) {
            console.error('Fetch failed:', error);
        }

    }

    const columns: GridColDef[] = [
        {field: "id", headerName: "ID"},
        {field: "shiftDate", headerName: "shiftDate", width:180},
        {field: "shiftPeriod", headerName: "Shift Period"},
        {field: "productionMCCycleSeconds", headerName: "Production MC Cycle Seconds"},
        {field: "CompanyName", headerName: "Customer Name"},
        {field: "productsProduced", headerName: "Product"},
        {field: "shiftMinutes", headerName: "Shift Minutes"},
        {field: "limitSwitch", headerName: "Limit Switch"},
        {field: "breakdown", headerName: "Breakdown"},
        {field: "vacuumPipeCleaner", headerName: "Vacuum Pipe Cleaner"},
        {field: "oilLeak", headerName: "Oil Leak"},
        {field: "pumpChamber", headerName: "Pump Chamber"},
        {field: "mouldChange", headerName: "Mould Change"},
        {field: "plannedMaintenance", headerName: "Planned Maintenance"},
        {field: "loadShedding", headerName: "Load Shedding"},
        {field: "loadCurtailment", headerName: "Load Curtailment"},
        {field: "greasingMould", headerName: "Greasing Mould"},
        {field: "materialChange", headerName: "Material Change"},
        {field: "toolroomFitting", headerName: "Toolroom Fitting"},
        {field: "waterPipeBurst", headerName: "Water Pipe Burst"},
        {field: "waterChannelBlocked", headerName: "Water Channel Blocked"},
        {field: "startUpReject", headerName: "Start Up Reject"},
        {field: "shortMouldingReject", headerName: "Short Moulding Reject"},
        {field: "wrongColourReject", headerName: "Wrong Colour Reject"},
        {field: "damagedCrateReject", headerName: "Damaged Create Reject"},
        {field: "serial", headerName: "Mould Serial"},
        {field: "product", headerName: "Mould Product"},
        {field: "createdAt", headerName: "Created At Date", width:180},
        {field: "updatedAt", headerName: "Updated At", width:180},
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />
                ];
            },
        },
    ];

    return (
        <Box m="20px">
            <Header
                title="Machines"
                subtitle="List of Machines"
            />
            <Box display="flex" justifyContent="flex-start">
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    onClick={handleDownload} download
                    href={''}>
                    Download
                </Button>
            </Box>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                </Snackbar>
            )}
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colours.palette.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colours.palette.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colours.palette.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colours.palette.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colours.palette.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colours.palette.grey[100]} !important`,
                    },
                }}
            >
                {machinePerformance &&
                    <DataGrid
                        getRowId={getRowId}
                        rows={machinePerformance}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        slotProps={{
                            toolbar: {machinePerformance, setRowModesModel},
                        }}
                    />}
            </Box>
        </Box>
    );
};


export default ShiftData;