import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import React from "react";

interface IHeader {
    title: string;
    subtitle: string;
}
const Header = ({title, subtitle}: IHeader) => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    return (
        <Box mb="30px">
            <Typography
                variant="h2"
                color={colours.palette.grey[100]}
                fontWeight="bold"
                sx={{ m: "0 0 5px 0" }}
            >
                {title}
            </Typography>
            <Typography variant="h5" color={colours.palette.greenAccent[400]}>
                {subtitle}
            </Typography>
        </Box>
    );
}

export default Header;