import {Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {AnyAction} from "redux";

const logger = (store: MiddlewareAPI)  => (next: Dispatch<any>) => (action: AnyAction) => {
    console.group(action.type)
    console.info('dispatching', action)
    let result = next(action)
    console.log('next state', store.getState())
    console.groupEnd()
    return result
}

export default logger