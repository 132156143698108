import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),

    tagTypes: ['users'],
    endpoints: build => ({
        GetUsers: build.query({
            query:() => ({
                url: `/users/`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.users}
        }),
        GetUserByUserID: build.query({
            query:(userID: string) => ({
                url: `/users/${userID}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.user}
        }),
    })
})

export const  {
    useGetUsersQuery,
    useGetUserByUserIDQuery
} = usersApi

