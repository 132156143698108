import {ColorModeContext, ToggleColorMode} from "./theme";
import Topbar from "./scenes/global/Topbar";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Sidebar from "./scenes/global/SideBar";
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Dashboard from "./scenes/dashboard/Dashbord";

import Login from "./scenes/login/login";
import {connect, useDispatch, useSelector} from "react-redux";
import Team from "./scenes/team/Team";
import Machines from "./scenes/machines/Machines";
import React, {useCallback, useEffect} from "react";
import Moulds from "./scenes/moulds/Moulds";
import LogShiftData from "./scenes/shiftReport/LogShiftData";
import ShiftData from "./scenes/shiftReport/ShiftData";
import {deleteFromLocalStorage, loadFromLocalStorage} from "./redux/features/authSlice";

function RequireAuth({ children }: any) {
    const auth: any  = useSelector((state: any) => state.auth)
    const location = useLocation();
    const exDate = new Date(auth.expirationTime * 1000).getTime()
    const nowDate = new Date().getTime()
    const dispatch: any = useDispatch();
    return exDate - nowDate > 0 ? (
        <Outlet context={children} />
    ) : (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
}


function App() {
    const auth: any  = useSelector((state: any) => state.auth)
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const { state } = useSelector((state: any) => state)
    const [theme , colourMode] = ToggleColorMode();

    const logOut = useCallback(() => {
        dispatch(deleteFromLocalStorage());
        navigate('/login');
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadFromLocalStorage())
        if (auth.authenticated && auth.expirationTime && window.localStorage.getItem("user")) {
            let expireMilliseconds = auth.expirationTime * 1000
            let dateNow = new Date();
            const expireDate = new Date(0)
            expireDate.setUTCMilliseconds(expireMilliseconds)
            const millisecondsToLogout = ((expireMilliseconds)  - dateNow.getTime()) - 240000
            console.log("TIMING: READ Token ",expireDate)
            console.log("TIMING: READ NOW", dateNow)
            console.log("TIMING DIFF:", millisecondsToLogout)
            if (expireMilliseconds < dateNow.getTime()) {
                console.log("TIMING LOGGING OUT")
                dispatch(deleteFromLocalStorage());
            }
            const logOutTimer = setTimeout(() => {
                console.log('TIMING Token has timed out!')
                dispatch(deleteFromLocalStorage());
            },  millisecondsToLogout);

        }
    }, [auth.authenticated]);

    useEffect(() => {
        if(!auth.authenticated)
            navigate("/login")
    }, [auth.authenticated]);


    return (

        <ColorModeContext.Provider value={colourMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div className="app">
                    {auth.authenticated && <Sidebar/> }
                    <main className="content">

                        {auth.authenticated && <Topbar/> }
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<Login/>}/>
                            <Route element={<RequireAuth/>}>
                                <Route path="/" element={<Dashboard/>}/>
                                <Route path="/team" element={<Team/>}/>
                                <Route path="/machines" element={<Machines/>}/>
                                <Route path="/moulds" element={<Moulds/>}/>
                                <Route path="/logShiftData" element={<LogShiftData/>}/>
                                <Route path="/machinePerformance" element={<ShiftData/>}/>
                            </Route>
                            <Route path='*' element={<Navigate to='/' replace />} />

                        </Routes>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>

  );
}
function mapStateToProps(state: { user: any; }) {
    return {
        user: state.user,

    }
}
const connector = connect(mapStateToProps, null)
export default connector(App);