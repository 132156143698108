import Header from "../../components/Header";
import {
    Alert,
    AlertProps,
    Box,
    Button,
    CircularProgress,
    Grid,
    Modal,
    TextField,
    Tooltip,
    useTheme
} from "@mui/material";
import {tokens} from "../../theme";
import {
    DataGrid, GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp
} from "@mui/x-data-grid";
import Snackbar from '@mui/material/Snackbar';
import React, {useEffect} from "react";
import {useGetUsersQuery} from '../../redux/features/uses'
import * as yup from "yup";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import type {FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import {useGetMachinesQuery} from "../../redux/features/machines";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {useGetMouldsByMachineIdQuery, useGetMouldsQuery} from "../../redux/features/moulds";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Field, Form, Formik, FormikHelpers, FormikValues, useField, useFormikContext} from "formik";
import Select, {components, ContainerProps} from 'react-select';
import AsyncSelect from "react-select/async";
import {
    usePostMachinePerformanceMutation,
} from "../../redux/features/machinePerformance";
import {useSelector} from "react-redux";
import {customrsApi, useGetCustomersQuery} from "../../redux/features/customers";

const ErrorMessage = ({ message }: {message: string}) => (
    <div className="error-message">
        <p>Error: {message}</p>
    </div>
);
interface Values {
    shiftDate: string;
    waterChannelBlocked: number;
    waterPipeBurst: number;
    toolroomFitting: number;
    materialChange: number;
    greasingMould: number;
    loadCurtailment: number;
    loadShedding: number;
    mouldChange: number;
    pumpChamber: number;
    oilLeak: number;
    vacuumPipeCleaner: number;
    breakdown: number;
    limitSwitch: number;
    plannedMaintenance: number;
    productionMCCycleSeconds: number;
    productsProduced: number;
    startUpReject: number;
    shortMouldingReject: number;
    wrongColourReject: number;
    damagedCrateReject: number;
    saleCost: number;
    materialsCost: number;
}

interface ValuesResult extends Values {
    data: any;
    id: number;
    createdAt: string;
    updatedAt: string;
}

const LogShiftData = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const [shiftDate,   setShiftDate] = React.useState<any>('');
    const {data: machines,} = useGetMachinesQuery({});
    const {data: customers,} = useGetCustomersQuery({});
    console.log("CUSTOMERS",customers)
    const [machineSelected, setMachineSelected] = React.useState<any>('');
    const [mouldSelected, setMouldSelected] = React.useState<any>('');
    const [shiftOption, setShiftOption] = React.useState<any>('1');
    const [customerId, setCustomerId] = React.useState<any>(null);
    const [open, setOpen] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [addMachinePerformance, {data, error, isLoading, isSuccess}] = usePostMachinePerformanceMutation()

    const {data: moulds,} = useGetMouldsByMachineIdQuery(machineSelected);
    console.log("WHAT isSuccess", isSuccess)
    console.log("WHAT isLoading", isLoading)

    const handleFormSubmit = (values: FormikValues, formikHelpers: FormikHelpers<any>) => {
        setSubmitted(true);
        console.log("VALES: ",values);
        console.log("SHIFT OPTION", shiftOption)
        values.shiftPeriod = shiftOption.label;
        values.shiftDate = shiftDate;
        values.machineToMouldLinkId = mouldSelected
        if(customerId) {
        values.customerId = customerId
        }
        addMachinePerformance(values);
        setMachineSelected('');
        formikHelpers.resetForm();
    };

    useEffect(() => {
        if (submitted && (isSuccess || !isSuccess)) {
            setOpen(true);
            console.log("ADD MACHINE PERFORMANCE", data)
        }
    }, [isSuccess]);

    const SelectContainer = ({
                                 children,
                                 ...props
                             }: ContainerProps) => {
        return (
            <Tooltip title={""} content={'customise your select container'}>
                <components.SelectContainer {...props}>
                    {children}
                </components.SelectContainer>
            </Tooltip>
        );
    };

    const initialValues = {
        shiftDate: "",
        machineToMouldLinkId: "",
        shiftPeriod: "",
        productionMCCycleSeconds: "",
        productsProduced: "",
        startUpReject: "",
        shortMouldingReject: "",
        wrongColourReject: "",
        damagedCrateReject: "",
        saleCost: "",
        materialsCost: "",
        limitSwitch: "",
        breakdown: "",
        vacuumPipeCleaner: "",
        oilLeak: "",
        pumpChamber: "",
        mouldChange: "",
        plannedMaintenance: "",
        loadShedding: "",
        loadCurtailment: "",
        greasingMould: "",
        materialChange: "",
        toolroomFitting: "",
        waterPipeBurst: "",
        waterChannelBlocked: "",
    };

    const checkoutSchema = yup.object().shape({
        productsProduced: yup.string().required(),
        productionMCCycleSeconds: yup.string().required(),
        startUpReject: yup.string().required(),
        shortMouldingReject: yup.string().required(),
        wrongColourReject: yup.string().required(),
        damagedCrateReject: yup.string().required(),
        saleCost: yup.string().required(),
        materialsCost: yup.string().required(),
        limitSwitch: yup.string().required(),
        breakdown: yup.string().required(),
        vacuumPipeCleaner: yup.string().required(),
        oilLeak: yup.string().required(),
        pumpChamber: yup.string().required(),
        mouldChange: yup.string().required(),
        plannedMaintenance: yup.string().required(),
        loadShedding: yup.string().required(),
        loadCurtailment: yup.string().required(),
        greasingMould: yup.string().required(),
        materialChange: yup.string().required(),
        toolroomFitting: yup.string().required(),
        waterPipeBurst: yup.string().required(),
        waterChannelBlocked: yup.string().required(),

    });
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: 'lightgray',
            padding: '10px 10px 10px 10px',
            border: '1px solid black',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            spacing: 10,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'hotpink' : 'white',
        }),
    };

    const shiftOptions = [
        {value: 'day', label: 'Day'},
        {value: 'night', label: 'Night'},
        {value: 'day 1/3', label: 'Day 1/3'},
        {value: 'afternoon 1/3', label: 'Afternoon 1/3'},
        {value: 'night 1/3', label: 'Night 1/3'},
    ]
    const onMachineChange = (storeSelected: any) => {
        console.log(storeSelected)
        // const store = machines.find((machine: any) => machines.Franchise + "-" + store.StoreNumber === storeSelected.value)
        setMachineSelected(storeSelected.value)
        setMouldSelected('')

        console.log("NEW MOULD MACHINE SELECTED", storeSelected)
    }

    const onMouldChange = (storeSelected: any) => {
        // const store = machines.find((machine: any) => machines.Franchise + "-" + store.StoreNumber === storeSelected.value)
        console.log("MOULD CHANGE", storeSelected)
        console.log("MOULD CHANGE ID ", storeSelected.value)
        setMouldSelected(storeSelected.value)
    }

    const onCustomerChange = (customer: any) => {
        // const store = machines.find((machine: any) => machines.Franchise + "-" + store.StoreNumber === storeSelected.value)
        console.log("CUSTOMER: ", customer)
        setCustomerId(customer.value)
    }
     const setDate = (date:any) => {
        let day = new Date(date).getDate();
         console.log("SHIFT DATE DAY", day)
        let month = new Date(date).getMonth() + 1;
            console.log("SHIFT DATE MONTH", month)
         let res = new Date(date).getFullYear() + "-" + ((month < 10) ? "0" + month : month) + "-" + ((day < 10) ? "0" + day : day);
         console.log("SHIFT DATE res", res)
         setShiftDate(res)
     };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
            <Box m="20px">
                <Header title="New Shift Data" subtitle="Load Shift Data"/>
                {isLoading ? <CircularProgress style={{ color: 'white' }}/> : null}
                <Modal
                    open={open}
                    onClose={() => {setOpen(false); setSubmitted(false)}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4
                    }}>
                        <h2 id="modal-modal-title">Submit {isSuccess ? "Successful" : "Unsuccessful"}</h2>
                        <p id="modal-modal-description">Your data has been submitted {isSuccess ? "successful" : "unsuccessful"}.</p>
                        {isSuccess ? <div>
                        <p id="modal-modal-description">ID: {data ? data.id : null}</p>
                        <p id="modal-modal-description">Created At: {data ? data.createdAt : null}</p>
                            </div>
                            :
                            <p id="modal-modal-description">error: {data?.message || 'An error occurred while fetching categories'}</p>
                    }
                    </Box>
                </Modal>
                <Formik
                    onSubmit={(values, formikHelpers) => handleFormSubmit(values, formikHelpers)}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container flex={'auto'} spacing={2}>
                                <Grid item xs={6}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={2}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Date of Shift:</Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                            <DatePicker
                                                label="Date of Shift"
                                                onChange={setDate}
                                                name="shiftDate"
                                            />
                                                </Grid>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <Grid item xs={2}>
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >Shift Options:</Box>
                                            </Grid>
                                            <div style={{padding: "20px 0 0 20px", minWidth: 400}}>
                                                <Select
                                                    styles={customStyles}
                                                    closeMenuOnSelect={true}
                                                    options={shiftOptions.map((shiftOption: any) => ({
                                                        value: shiftOption.id,
                                                        label: shiftOption.label
                                                    }))}
                                                    onChange={setShiftOption}
                                                    defaultValue={shiftOption}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <Box
                                                alignItems="center"
                                                justifyContent="center"
                                            >Machine:</Box>
                                            <div style={{padding: "20px 0 0 20px", minWidth: 400}}>
                                                <Select
                                                    styles={customStyles}
                                                    closeMenuOnSelect={true}
                                                    components={{SelectContainer}}
                                                    options={machines && machines.map((machine: any) => ({
                                                        value: machine.id,
                                                        label: machine.machineName
                                                    }))}
                                                    onChange={onMachineChange}
                                                    defaultValue={machineSelected}
                                                />
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <Box
                                                alignItems="center"
                                                justifyContent="center"
                                            >Mould:</Box>
                                            <div style={{padding: "20px 0 0 20px", minWidth: 400}}>
                                                <Select
                                                    styles={customStyles}
                                                    closeMenuOnSelect={true}
                                                    components={{SelectContainer}}
                                                    options={moulds && moulds.map((moulds: any) => ({
                                                        value: moulds.id,
                                                        label: moulds.mould_serial + " " + moulds.mould_product
                                                    }))}
                                                    onChange={onMouldChange}
                                                    defaultValue={mouldSelected}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <Box
                                                alignItems="center"
                                                justifyContent="center"
                                            >Customer:</Box>
                                            <div style={{padding: "20px 0 0 20px", minWidth: 400}}>
                                                <Select
                                                    styles={customStyles}
                                                    closeMenuOnSelect={true}
                                                    components={{SelectContainer}}
                                                    options={customers && customers.map((customer: any) => ({
                                                        value: customer.id,
                                                        label: customer.CompanyName
                                                    }))}
                                                    onChange={onCustomerChange}
                                                    defaultValue={customerId}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <Box
                                                alignItems="center"
                                                justifyContent="center"
                                            >Production M/C Cycle Seconds:</Box>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="Production M/C Cycle Seconds
"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.productionMCCycleSeconds}
                                                name="productionMCCycleSeconds"
                                                error={!!touched.productionMCCycleSeconds && !!errors.productionMCCycleSeconds}
                                                helperText={touched.productionMCCycleSeconds && errors.productionMCCycleSeconds}
                                                sx={{gridColumn: "span 1"}}
                                            />
                                        </div>
                                    </div>

                                    <Grid xs={8}></Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box
                                        alignItems="center"
                                        justifyContent="center"
                                    >Production:</Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Crates Produced"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.productsProduced}
                                            name="productsProduced"
                                            error={!!touched.productsProduced && !!errors.productsProduced}
                                            helperText={touched.productsProduced && errors.productsProduced}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Start Up"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.startUpReject}
                                            name="startUpReject"
                                            error={!!touched.startUpReject && !!errors.startUpReject}
                                            helperText={touched.startUpReject && errors.startUpReject}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Short Moulding"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.shortMouldingReject}
                                            name="shortMouldingReject"
                                            error={!!touched.shortMouldingReject && !!errors.shortMouldingReject}
                                            helperText={touched.shortMouldingReject && errors.shortMouldingReject}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Wrong Colour"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.wrongColourReject}
                                            name="wrongColourReject"
                                            error={!!touched.wrongColourReject && !!errors.wrongColourReject}
                                            helperText={touched.wrongColourReject && errors.wrongColourReject}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Damaged Crates"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.damagedCrateReject}
                                            name="damagedCrateReject"
                                            error={!!touched.damagedCrateReject && !!errors.damagedCrateReject}
                                            helperText={touched.damagedCrateReject && errors.damagedCrateReject}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box
                                        alignItems="center"
                                        justifyContent="center"
                                    >Prices:</Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Sale Cost"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.saleCost}
                                            name="saleCost"
                                            error={!!touched.saleCost && !!errors.saleCost}
                                            helperText={touched.saleCost && errors.saleCost}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Material Cost"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.materialsCost}
                                            name="materialsCost"
                                            error={!!touched.materialsCost && !!errors.materialsCost}
                                            helperText={touched.materialsCost && errors.materialsCost}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box
                                        alignItems="center"
                                        justifyContent="center"
                                    >Lost Minutes Analysis:</Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Planned Maintenance"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.plannedMaintenance}
                                            name="plannedMaintenance"
                                            error={!!touched.plannedMaintenance && !!errors.plannedMaintenance}
                                            helperText={touched.plannedMaintenance && errors.plannedMaintenance}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Limit Switch"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.limitSwitch}
                                            name="limitSwitch"
                                            error={!!touched.limitSwitch && !!errors.limitSwitch}
                                            helperText={touched.limitSwitch && errors.limitSwitch}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Breakdown"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.breakdown}
                                            name="breakdown"
                                            error={!!touched.breakdown && !!errors.breakdown}
                                            helperText={touched.breakdown && errors.breakdown}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Vacuum Pipe Cleaner"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.vacuumPipeCleaner}
                                            name="vacuumPipeCleaner"
                                            error={!!touched.vacuumPipeCleaner && !!errors.vacuumPipeCleaner}
                                            helperText={touched.vacuumPipeCleaner && errors.vacuumPipeCleaner}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Oil Leak"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.oilLeak}
                                            name="oilLeak"
                                            error={!!touched.oilLeak && !!errors.oilLeak}
                                            helperText={touched.oilLeak && errors.oilLeak}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Pump Chamber"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.pumpChamber}
                                            name="pumpChamber"
                                            error={!!touched.pumpChamber && !!errors.pumpChamber}
                                            helperText={touched.pumpChamber && errors.pumpChamber}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Mould Change"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.mouldChange}
                                            name="mouldChange"
                                            error={!!touched.mouldChange && !!errors.mouldChange}
                                            helperText={touched.mouldChange && errors.mouldChange}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Load Shedding"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.loadShedding}
                                            name="loadShedding"
                                            error={!!touched.loadShedding && !!errors.loadShedding}
                                            helperText={touched.loadShedding && errors.loadShedding}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Load Curtailment"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.loadCurtailment}
                                            name="loadCurtailment"
                                            error={!!touched.loadCurtailment && !!errors.loadCurtailment}
                                            helperText={touched.loadCurtailment && errors.loadCurtailment}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Greasing Mould"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.greasingMould}
                                            name="greasingMould"
                                            error={!!touched.greasingMould && !!errors.greasingMould}
                                            helperText={touched.greasingMould && errors.greasingMould}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Material Change"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.materialChange}
                                            name="materialChange"
                                            error={!!touched.materialChange && !!errors.materialChange}
                                            helperText={touched.materialChange && errors.materialChange}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Toolroom Fitting"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.toolroomFitting}
                                            name="toolroomFitting"
                                            error={!!touched.toolroomFitting && !!errors.toolroomFitting}
                                            helperText={touched.toolroomFitting && errors.toolroomFitting}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Water Pipe Burst"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.waterPipeBurst}
                                            name="waterPipeBurst"
                                            error={!!touched.waterPipeBurst && !!errors.waterPipeBurst}
                                            helperText={touched.waterPipeBurst && errors.waterPipeBurst}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Water Channel Blocked"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.waterChannelBlocked}
                                            name="waterChannelBlocked"
                                            error={!!touched.waterChannelBlocked && !!errors.waterChannelBlocked}
                                            helperText={touched.waterChannelBlocked && errors.waterChannelBlocked}
                                            sx={{gridColumn: "span 1"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid xs={6}></Grid>
                                <Grid xs={2}></Grid>

                            </Grid>
                            <Box display="flex" justifyContent="end" mt="20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    Generate Daily Budget
                                </Button>
                            </Box>
                        </form>
                    )}

                </Formik>
            </Box>
        </LocalizationProvider>
    );
};


export default LogShiftData;