import {createSlice} from "@reduxjs/toolkit";
import {userLogin} from "../services/authActions";
import {ex} from "@fullcalendar/core/internal-common";

const initialState = {
    loading: false,
    refreshToken: null,
    userToken: null,
    authenticated: false,
    accessToken: null,
    userUUID: null,
    expirationTime: null,
    userName: null,
    error: null,
    success: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loadFromLocalStorage: (state) => {
        const local: any = window.localStorage.getItem("user");
        if (local) {
            const payload = JSON.parse(local)
            state.refreshToken = payload.RefreshToken
            state.accessToken = payload.AccessToken
            state.authenticated = payload.authenticated
            state.expirationTime = payload.ExpirationTime
            state.userUUID = payload.userUUID
            state.userName = payload.userName
        }


        },
        deleteFromLocalStorage: (state) => {
            state.refreshToken = null
            state.accessToken = null
            state.authenticated = false
            state.expirationTime = null
            state.userUUID = null
            state.userName = null
             window.localStorage.removeItem("user");
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.pending, (state: any) => {
            state.loading = true
            state.error = null
        })
            builder.addCase(userLogin.fulfilled, (state: any, { payload } : any) => {
                state.loading = false
                state.refreshToken = payload.RefreshToken
                state.accessToken = payload.AccessToken
                state.expirationTime = payload.ExpirationTime
                state.userUUID = payload.userUUID
                state.authenticated = true
                state.userName = payload.userName
                const local = {
                    ...payload,
                    authenticated: true
                }
                window.localStorage.setItem("user", JSON.stringify(local));
            })
            builder.addCase(userLogin.rejected, (state: any, { payload }) => {
                state.loading = false
                state.error = payload
            })
    },
})
export default authSlice.reducer
export const { loadFromLocalStorage, deleteFromLocalStorage } = authSlice.actions