import {Alert, AlertProps, Box, Button, CircularProgress, SnackbarCloseReason, TextField} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import React, {Component, ReactNode, SyntheticEvent, useEffect} from "react";
import {useAuth} from "../../components/useAuth";
import {useLocation, useNavigate} from "react-router-dom";
import {connect, useDispatch, useSelector} from 'react-redux'
import {useLocalStorage} from "../../helpers/UseLocalStorage";
import Snackbar from "@mui/material/Snackbar";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {userLogin} from "../../redux/services/authActions";
import {AppDispatch} from "../../redux/store";

interface Values {
    firstName: string;
    lastName: string;
    email: string;
    contact: string;
    password: string;
}

interface LoginValues { username: string; password: string; }

const Login = ({props}: any) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch<AppDispatch>()
    const { loading, error, authenticated } = useSelector((state: any) => state.auth)
    console.log("AUTH", authenticated)
    const handleLogin = async (values: LoginValues) => {
        dispatch(userLogin({username: values.username, password: values.password}))
    };

    useEffect(() => {
        if (authenticated) {
            navigate(state?.path || "/dashboard");
        }
    }, [authenticated]);
    const [snackbar, setSnackbar] = React.useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);


    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);
    const alertUser = (e: any) => {
        e.preventDefault();
        e.returnValue = "";
    };

    useEffect(() => {
        console.log("AUTH error", error)
        if (error) {
            setSnackbar({ children: error?.toString(), severity: 'error' })
        }
    }, [error]);

    const handleCloseSnackbar = (event?: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(null);
    };


    return(
        <Box m="20px">
            <Box>
                {snackbar &&
                    <Snackbar
                        open
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={"error"}>
                            {snackbar?.children}
                        </Alert>
                    </Snackbar>
                }
            </Box>
            {loading && <CircularProgress />}
            <Formik
                onSubmit={handleLogin}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateRows="repeat(2, minmax(0, 1fr))"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                                name="username"
                                error={!!touched.username && !!errors.username}
                                helperText={touched.username && errors.username}
                                sx={{gridColumn: "span 2"}}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                sx={{gridColumn: "span 2"}}
                            />
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                { loading ? loading && <CircularProgress /> : "Login" }
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

const checkoutSchema = yup.object().shape({
    username: yup.string().required("required"),
    password: yup.string().required("required"),
});
const   initialValues = {
    username: "",
    password: "",
};

export default Login;
