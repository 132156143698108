import {createSlice} from "@reduxjs/toolkit";
import {usersApi} from "./features/uses";


export interface IUser {
        id: number
        firstName: string
        lastName: string
        emailAddress: string
        role: string

}

const initialStateUser: IUser = {
        id: 0,
        firstName: '',
        lastName: '',
        emailAddress: '',
        role: '',
}
const userSlice = createSlice({
    name: 'user', initialState: initialStateUser, reducers: {
        getUser: (state) => {
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            usersApi.endpoints.GetUserByUserID.matchFulfilled,
            (state, { payload }) => {
                state.id = payload.id
                state.firstName = payload.firstName
                state.lastName = payload.lastName
                state.emailAddress = payload.emailAddress
                state.role = payload.role
            }
        )
    }
})
export const userActions = userSlice.actions
export default userSlice.reducer
