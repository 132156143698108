import Header from "../../components/Header";
import {Alert, AlertProps, Box, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {
    DataGrid, GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp
} from "@mui/x-data-grid";
import Snackbar from '@mui/material/Snackbar';
import React from "react";
import {useGetUsersQuery} from '../../redux/features/uses'

import type {FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import {useGetMachinesQuery} from "../../redux/features/machines";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

interface IRow {
    row: {
        access: string
    }
}

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps): any {
    const {setRows, setRowModesModel} = props;

    const handleClick = (id: any) => {
        setRows((oldRows) => [...oldRows, {id, name: '', age: '', isNew: true}]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };
}

function getRowId(row: any) {
    return row.id;
}

function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
}

const Machines = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const {data: machines,} = useGetMachinesQuery({});
    const [snackbar, setSnackbar] = React.useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);
    const machineStatus = ['active', 'offline', 'maintenance'];

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleCloseSnackbar = () => setSnackbar(null);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({children: error.toString(), severity: 'error'});
    }, []);

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        console.log("ID", id)
    };

    const handleCancelClick = (id: GridRowId) => () => {
        console.log("ID", id)
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
        console.log("Users", machines)
        const editedRow = machines.find((row: any) => row.ID === id);
        // if (editedRow!.isNew) {
        //     setRows(rows.filter((row: any) => row.ID !== id));
        // }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        {field: "id", headerName: "ID", flex: 0.1},
        {field: "machineName", headerName: "Name", flex: 0.2},
        {field: "status", headerName: "status", editable: true, type: 'singleSelect',
            valueOptions: machineStatus,
        },
        {
            field: "createdAt",
            headerName: "Created At Date",
            flex: 0.2,
        },
        {
            field: "updatedAt",
            headerName: "Updated At",
            flex: 0.2,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />
                ];
            },
        },
    ];

    return (
        <Box m="20px">
            <Header
                title="Machines"
                subtitle="List of Machines"
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                </Snackbar>
            )}
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colours.palette.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colours.palette.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colours.palette.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colours.palette.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colours.palette.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colours.palette.grey[100]} !important`,
                    },
                }}
            >
                {machines &&
                    <DataGrid
                        getRowId={getRowId}
                        rows={machines}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        slotProps={{
                            toolbar: {machines, setRowModesModel},
                        }}
                    />}
            </Box>
        </Box>
    );
};


export default Machines;