import {Box, IconButton, Modal, Typography, useTheme} from "@mui/material";
import React, { useContext } from "react";
import {ColorModeContext, tokens} from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {Logout} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteFromLocalStorage} from "../../redux/features/authSlice";

const Topbar = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const {toggleColorMode} = useContext(ColorModeContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const auth: any  = useSelector((state: any) => state.auth)
    function handleLogout() {
        dispatch(deleteFromLocalStorage());

        navigate("/login")
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            <Box
            display="flex"
            bgcolor={colours.palette.primary[400]}
            borderRadius="3px"
            >
                {/*<InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />*/}
                {/*<IconButton type="button" sx={{ p: 1 }}>*/}
                {/*    <SearchIcon />*/}
                {/*</IconButton>*/}
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>
                </Box>
            </Modal>
            <Box display="flex">
                <IconButton onClick={toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton
                    disabled={!auth.authenticated}>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton
                    onClick={handleOpen}
                    disabled={!auth.authenticated}>
                    <PersonOutlinedIcon />
                </IconButton>
                <IconButton
                    onClick={handleLogout}
                    disabled={!auth.authenticated}>
                <Logout />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Topbar;