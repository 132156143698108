import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {BaseQueryError, BaseQueryMeta} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {createSlice} from "@reduxjs/toolkit";
import {userLogin} from "../services/authActions";

type BaseQueryType = ReturnType<typeof fetchBaseQuery>;
export const baseQueryWithReauth: (baseQuery: BaseQueryType) => BaseQueryType = (
    baseQuery
) => async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        return result.error
    }
    return result
}

export const productBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3001',
    prepareHeaders: (headers, { getState }) => {
        const state: any = (getState() as typeof store)
        const { accessToken } = state.auth;
        if(accessToken)
            headers.set('Authorization', `Bearer ${accessToken}`)
        return headers
    }
});
//
export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth(productBaseQuery),
    tagTypes: ['authentication'],
    endpoints: build => ({
        getUsers: build.query({
            query: () => ({
                tagTypes: ['users'],
                url: '/protected/users',
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.result}
        }),
        getUser: build.query({
            query: (uuid) => ({
                tagTypes: ['user'],
                url: '/protected/users/' + uuid,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.result}
        }),
    })
})

export const {
    useGetUsersQuery,
    useGetUserQuery
} = api
