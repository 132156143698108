import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";

export const machinesApi = createApi({
    reducerPath: 'machinesApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),

    tagTypes: ['machines'],
    endpoints: build => ({
        GetMachines: build.query({
            query:() => ({
                url: `/machines/`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.machines}
        }),
    })
})

export const  {
    useGetMachinesQuery,
} = machinesApi

